import { mq, padding, spacing, styled } from '@thg-commerce/enterprise-theme'
import { BlankDropdown, Button } from '@thg-commerce/gravity-elements'

const BUTTON_HEIGHT = '48px'

export const HorizontalFacetWrapper = styled.div<{
  isAlignTop?: boolean
  stickyOnDesktop?: boolean
  scrolled?: boolean
  hideMargin?: boolean
}>`
  display: flex;
  margin: ${(props) =>
    props.hideMargin ? 0 : `${spacing(4)} 0 ${spacing(2)}`};
  border-top: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  border-bottom: 1px solid ${(props) => props.theme.colors.palette.greys.light};
  padding: ${spacing(3)} 0;
  align-items: ${(props) => (props.isAlignTop ? 'flex-start' : 'center')};
  justify-content: space-between;
  ${(props) =>
    props.stickyOnDesktop &&
    props.scrolled &&
    ` 
    padding: ${spacing(2)} 0;
  &::after {
    content: '';
    position: absolute;
    width: ${
      typeof window !== 'undefined'
        ? `${window.document.body.clientWidth}px`
        : '100vw'
    };
    left: calc(50% - ${
      typeof window !== 'undefined'
        ? `${window.document.body.clientWidth / 2}px`
        : '100vw'
    });
    bottom: 0;
    border-bottom: solid ${props.theme.colors.palette.greys.light} 1px;
    }`};
`

export const FacetContainer = styled.div<{
  isOpen: boolean
  gap: { column?: number; row?: number }
}>`
  display: flex;
  position: static;
  flex: 0 1 auto;
  flex-wrap: wrap;
  align-items: center;
  align-content: flex-start;
  flex-direction: row;
  width: 100%;
  ${(props) =>
    props.theme.widget.productList?.horizontalFacets?.container.maxWidth &&
    `max-width:${props.theme.widget.productList.horizontalFacets.container.maxWidth};`}
  ${(props) =>
    props.theme.widget.productList?.horizontalFacets?.container?.padding
      ?.mobile &&
    padding(
      props.theme.widget.productList.horizontalFacets.container.padding.mobile,
    )}
  ${(props) => props.gap?.row && `row-gap: ${spacing(props.gap.row)}`};
  ${(props) => props.gap?.column && `column-gap: ${spacing(props.gap.column)}`};
  overflow: hidden;
  height: ${(props) => (props.isOpen ? 'auto' : BUTTON_HEIGHT)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    ${(props) =>
      props.theme.widget.productList?.horizontalFacets?.container?.padding
        ?.desktop &&
      padding(
        props.theme.widget.productList.horizontalFacets.container.padding
          .desktop,
      )}
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  column-gap: ${spacing(2)};
  margin-left: ${spacing(2)};
  height: ${BUTTON_HEIGHT};
  align-items: center;
  justify-content: center;
`
export const StyledBlankDropdown = styled(BlankDropdown)<{
  isBrandFacet: boolean
  maxWidth: string
}>`
  max-width: ${(props) => props.maxWidth};
  min-width: 160px;
  flex-grow: 1;

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    min-width: 164px;
  }

  & > div > div {
    ${(props) =>
      props.isBrandFacet && {
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
      }}
  }
`
const MINIMUM_FILTER_ACTION_WIDTH = '60px'

export const ActionFilterButton = styled(Button)`
  &&,
  &:focus,
  &:active,
  &:hover {
    justify-content: end;
    padding: 2px;
    min-width: ${MINIMUM_FILTER_ACTION_WIDTH};
  }
`
