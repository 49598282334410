import { Text, spacing } from '@thg-commerce/gravity-theme'
import { styled } from '@thg-commerce/gravity-patterns/theme'
import { Button } from '@thg-commerce/gravity-elements'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacing(2)};
`

export const Quantifier = styled.div<{ badgePosition?: HorizontalAlignment }>`
  ${Text('bodyText', 'default')};
  width: 24px;
  background: ${(props) => props.theme.colors.palette.greys.darker};
  color: ${(props) => props.theme.colors.palette.greys.lighter};
  border-radius: 50%;
  ${(props) =>
    props.badgePosition === HorizontalAlignment.LEFT
      ? `margin:0 auto 0 ${spacing(1)};`
      : `margin-left: auto`};
  display: flex;
  justify-content: center;
  align-items: flex-end;
`
export const BlankDropdownButtonContent = styled.div<{
  quantifier?: number
}>`
  ${(props) => (props.quantifier ? 'width: 100%' : 'margin-left: auto')};
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)<{
  alignRight?: boolean
}>`
  ${(props) => props.alignRight && `margin-left: auto;`}
`
