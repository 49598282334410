import * as React from 'react'
import loadable from '@loadable/component'

import { KeyboardKeys } from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

const ChevronDown = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronDown'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { Dropdown, DropdownButton, OptionsContainer } from '../styles'

import {
  BlankDropdownButtonContent,
  ButtonWrapper,
  Quantifier,
  StyledButton,
} from './styles'

export interface BlankDropdownProps {
  children: JSX.Element
  disabledClearAll: boolean
  className?: string
  buttonText: string
  disabled?: boolean
  zIndex?: number
  width?: string
  iconOverride?: {
    svgPath: string
    viewBox: string
    width: string
    height: string
  }
  dropdownWidth?: string
  useDefaultDropdownWidth?: boolean
  onCustomButtonClick?: () => void
  quantifier?: number
  i18nButtonText?: {
    customButton?: string
    close?: string
  }
  ariaHasPopup?:
    | 'dialog'
    | 'menu'
    | 'listbox'
    | 'true'
    | 'false'
    | 'grid'
    | 'tree'
  hasBrandSearch?: boolean
  badgePosition?: HorizontalAlignment
}

export const BlankDropdown = (props: BlankDropdownProps) => {
  const [showDropdown, setShowDropdown] = React.useState(false)

  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (showDropdown) {
      containerRef.current?.focus()
    }
  }, [showDropdown])

  const disableButton = props.disabled

  return (
    <div className={props.className}>
      <Dropdown
        {...(props.width && { width: props.width })}
        onBlur={(event: React.FocusEvent) => {
          if (!event.currentTarget.contains(event.relatedTarget as Node)) {
            setShowDropdown(false)
          }
        }}
      >
        <DropdownButton
          data-testid="dropdown-button"
          aria-expanded={showDropdown}
          aria-haspopup={props.ariaHasPopup}
          aria-label={props.buttonText}
          dropdownOpen={showDropdown}
          onClick={() => {
            !disableButton && setShowDropdown((prevState) => !prevState)
          }}
          onKeyDown={(event: React.KeyboardEvent) => {
            !disableButton &&
              event.key === KeyboardKeys.Enter &&
              setShowDropdown((prevState) => !prevState)
          }}
        >
          <span>{props.buttonText}</span>
          <BlankDropdownButtonContent quantifier={props.quantifier}>
            {props.quantifier && (
              <Quantifier badgePosition={props.badgePosition}>
                {props.quantifier.toString()}
              </Quantifier>
            )}
            {!disableButton && props.iconOverride?.svgPath ? (
              <SvgIcon
                xmlns="http://www.w3.org/2000/svg"
                viewBox={props.iconOverride.viewBox}
                width={props.iconOverride.width}
                height={props.iconOverride.height}
              >
                <path d={props.iconOverride.svgPath} fillRule="evenodd" />
              </SvgIcon>
            ) : (
              <ChevronDown />
            )}
          </BlankDropdownButtonContent>
        </DropdownButton>
        {showDropdown && (
          <OptionsContainer
            ref={containerRef}
            data-testid="options-container"
            tabIndex={-1}
            zIndex={props.zIndex}
            dropdownWidth={props.dropdownWidth}
            useDefaultWidth={props.useDefaultDropdownWidth}
            stickyPosition
            removePadding
          >
            {props.children}
            {(props.i18nButtonText?.close ||
              props.i18nButtonText?.customButton) && (
              <ButtonWrapper>
                {props.i18nButtonText.customButton && (
                  <StyledButton
                    emphasis="low"
                    data-testid={`blank-dropdown-custom-button-${props.i18nButtonText.customButton}`}
                    sizing="regular"
                    onClick={() => props.onCustomButtonClick?.()}
                    disabled={props.disabledClearAll}
                  >
                    {props.i18nButtonText.customButton}
                  </StyledButton>
                )}
                {props.i18nButtonText.close && (
                  <StyledButton
                    emphasis="low"
                    sizing="regular"
                    onClick={() => setShowDropdown(false)}
                    alignRight
                  >
                    {props.i18nButtonText.close}
                  </StyledButton>
                )}
              </ButtonWrapper>
            )}
          </OptionsContainer>
        )}
      </Dropdown>
    </div>
  )
}
